<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert type="warning" v-if="!user.xentri_id || !user.account_id">
                        <router-link :to="{ name: 'user-check' }" class="white--text">{{ whatRequiresAttention }}</router-link>
                    </v-alert>
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="#3F51B5" dark flat dense>
                            <v-app-bar-title>OpenVPN Settings</v-app-bar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon :to="{ name: 'user-dashboard' }">
                                <font-awesome-icon :icon="['fas', 'times']" style="font-size: 16px;" fixed-width/>
                            </v-btn>
                        </v-app-bar>
                        <v-card-text>
                            <!-- <p class="text-overline mb-0 mt-5">Settings</p> -->
                            <p>
                                Click the download link below to get the OpenVPN configuration file for Cryptium VPN.
                            </p>
                            <p>
                                <!-- <router-link :to="{ name: 'user-openvpn-config' }">Download</router-link> -->
                                <a :href="downloadURL">Download</a>
                                <v-btn icon small color="blue darken-2" :href="downloadURL">
                                    <font-awesome-icon :icon="['fas', 'file-download']" style="font-size: 16px;" fixed-width/>
                                </v-btn>
                            </p>
                            <p>Open the file with your OpenVPN software.</p>
                            <!-- <p>
                                Where to save the file:
                            </p>
                            <ul>
                                <li>On Linux, place the file in {{ linuxFileLocation }}</li>
                                <li>On Windows, place the file in {{ windowsFileLocation }}</li>
                            </ul> -->
                        </v-card-text>
                    </v-card>
                    <v-card elevation="2" class="my-6">
                        <v-card-text>
                            <v-row>
                                <v-col cols="1">
                                    <p class="mb-0" style="text-align: start;">
                                        <v-btn icon small color="indigo darken-2" :to="{ name: 'user-openvpn-software'}">
                                            <font-awesome-icon :icon="['fas', 'angle-left']" style="font-size: 16px;" fixed-width/>
                                        </v-btn>
                                    </p>
                                </v-col>
                                <v-col cols="5">
                                    <p class="mb-0 mt-1" style="text-align: start;">
                                        <router-link :to="{ name: 'user-openvpn-software'}">OpenVPN Software</router-link>
                                    </p>
                                </v-col>
                                <v-col cols="5">
                                    <p class="mb-0 mt-1" style="text-align: end;">
                                        <router-link :to="{ name: 'user-openvpn-password'}">OpenVPN Password</router-link>
                                    </p>
                                </v-col>
                                <v-col cols="1">
                                    <p class="mb-0" style="text-align: end;">
                                        <v-btn icon small color="indigo darken-2" :to="{ name: 'user-openvpn-password'}">
                                            <font-awesome-icon :icon="['fas', 'angle-right']" style="font-size: 16px;" fixed-width/>
                                        </v-btn>
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';

export default {
    computed: {
        ...mapState({
            account: (state) => state.account,
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        whatRequiresAttention() {
            const list = [];
            if (!this.user.xentri_id) {
                list.push('profile');
            }
            if (!this.user.account_id) {
                list.push('account');
            }
            if (list.length === 0) {
                return '';
            }
            const nouns = list.join(' and ');
            if (list.length === 1) {
                return `Your ${nouns} requires attention.`;
            }
            return `Your ${nouns} require attention.`;
        },
        downloadURL() {
            return `/api/browser/user/${this.user.id}/download/openvpn/CryptiumVPN.ovpn`;
        },
        linuxFileLocation() {
            return '/etc/openvpn/client';
        },
        windowsFileLocation() {
            return 'C:\\Users\\yourname\\OpenVPN\\config';
        },
    },
    methods: {
        settings() {
            this.$router.push({ name: 'user-settings' });
        },
        async createOpenvpnPassword() {
            try {
                const response = await this.$client.user(this.user.id).user.createOpenvpnPassword();
                console.log(`Got response from createOpenvpnPassword: ${JSON.stringify(response)}`);
            } catch (err) {
                console.log(`Failed to create openvpn password: ${err.message}`);
            }
        },
    },
};
</script>
